import React from "react";
import "./progress.scss";

const CheckCircleIcon = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.06597 10.0667L10.766 5.36668L9.83264 4.43334L6.06597 8.20001L4.16597 6.30001L3.23264 7.23334L6.06597 10.0667ZM6.99931 13.6667C6.07709 13.6667 5.21042 13.4916 4.39931 13.1413C3.5882 12.7916 2.88264 12.3167 2.28264 11.7167C1.68264 11.1167 1.20775 10.4111 0.857975 9.60001C0.507753 8.7889 0.332642 7.92223 0.332642 7.00001C0.332642 6.07779 0.507753 5.21112 0.857975 4.40001C1.20775 3.5889 1.68264 2.88334 2.28264 2.28334C2.88264 1.68334 3.5882 1.20823 4.39931 0.85801C5.21042 0.508232 6.07709 0.333344 6.99931 0.333344C7.92153 0.333344 8.7882 0.508232 9.59931 0.85801C10.4104 1.20823 11.116 1.68334 11.716 2.28334C12.316 2.88334 12.7909 3.5889 13.1406 4.40001C13.4909 5.21112 13.666 6.07779 13.666 7.00001C13.666 7.92223 13.4909 8.7889 13.1406 9.60001C12.7909 10.4111 12.316 11.1167 11.716 11.7167C11.116 12.3167 10.4104 12.7916 9.59931 13.1413C8.7882 13.4916 7.92153 13.6667 6.99931 13.6667ZM6.99931 12.3333C8.4882 12.3333 9.74931 11.8167 10.7826 10.7833C11.816 9.75001 12.3326 8.4889 12.3326 7.00001C12.3326 5.51112 11.816 4.25001 10.7826 3.21668C9.74931 2.18334 8.4882 1.66668 6.99931 1.66668C5.51042 1.66668 4.24931 2.18334 3.21597 3.21668C2.18264 4.25001 1.66597 5.51112 1.66597 7.00001C1.66597 8.4889 2.18264 9.75001 3.21597 10.7833C4.24931 11.8167 5.51042 12.3333 6.99931 12.3333Z" fill="currentColor"/>
  </svg>
)

const CheckCircleIconWhite = (props) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.39999 10.0667L11.1 5.36668L10.1667 4.43334L6.39999 8.20001L4.49999 6.30001L3.56666 7.23334L6.39999 10.0667ZM7.33332 13.6667C6.4111 13.6667 5.54443 13.4916 4.73332 13.1413C3.92221 12.7916 3.21666 12.3167 2.61666 11.7167C2.01666 11.1167 1.54177 10.4111 1.19199 9.60001C0.841768 8.7889 0.666656 7.92223 0.666656 7.00001C0.666656 6.07779 0.841768 5.21112 1.19199 4.40001C1.54177 3.5889 2.01666 2.88334 2.61666 2.28334C3.21666 1.68334 3.92221 1.20823 4.73332 0.85801C5.54443 0.508232 6.4111 0.333344 7.33332 0.333344C8.25555 0.333344 9.12221 0.508232 9.93332 0.85801C10.7444 1.20823 11.45 1.68334 12.05 2.28334C12.65 2.88334 13.1249 3.5889 13.4747 4.40001C13.8249 5.21112 14 6.07779 14 7.00001C14 7.92223 13.8249 8.7889 13.4747 9.60001C13.1249 10.4111 12.65 11.1167 12.05 11.7167C11.45 12.3167 10.7444 12.7916 9.93332 13.1413C9.12221 13.4916 8.25555 13.6667 7.33332 13.6667Z" fill="white"/>
  </svg>
)

const Progress = ({ status }) => {
  return (
    <div className={`progress-wrapper progress-wrapper-${status}`}>
      <span className="progress-title">
        <span>10% off</span>
        <span>15% off</span>
        <span>20% off</span>
        <span>25% off</span>
      </span>
      <span className="progress-root">
        {status >= 1 ? <CheckCircleIconWhite /> : <CheckCircleIcon color="#9D674E"/>}
        {status >= 4 ? <CheckCircleIconWhite /> : <CheckCircleIcon />}
        {status >= 5 ? <CheckCircleIconWhite /> : <CheckCircleIcon />}
        {status >= 6 ? <CheckCircleIconWhite /> : <CheckCircleIcon />}
        <span className="progress-bar"></span>
      </span>
      <span className="progress-bottom">
        <span>&nbsp;</span>
        <span>4 Matches</span>
        <span>5 Matches</span>
        <span>6 Matches</span>
      </span>
    </div>
  );
};

export default Progress;
