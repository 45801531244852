import React, { useEffect, useState } from "react";
import axios from 'axios';

import "./analytics.scss";

const baseUrl = "https://api.playdogeared.com";

const Analytics = () => {

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  const [totalFlipped, setTotalFlipped] = useState(0);
  const [totalMatches, setTotalMatches] = useState(0);
  const [totalPlayed, setTotalPlayed] = useState(0);
  const [matches, setMatches] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0
  })
  const now = new Date();
  const [max, setMax] = useState(0);

  const fetchData = () => {
    axios
      .get(`${baseUrl}/api/v1/play`, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((res) => {
        setTotalFlipped(res.data.data['Total flipped'])
        setTotalMatches(res.data.data['Total matches'])
        setTotalPlayed(res.data.data['Total played'])
        setMatches({
          0: res.data.data['0 matches'],
          1: res.data.data['1 matches'],
          2: res.data.data['2 matches'],
          3: res.data.data['3 matches'],
          4: res.data.data['4 matches'],
          5: res.data.data['5 matches'],
          6: res.data.data['6 matches'],
        })
        setMax(Math.max(res.data.data['0 matches'], res.data.data['1 matches'], res.data.data['2 matches'], res.data.data['3 matches'], res.data.data['4 matches'], res.data.data['5 matches'], res.data.data['6 matches']))
      })
      ;
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className="analytics-dashboard">
      <div className="analytics-status">
        <div className="status-list">
          <h2>Game Analytics</h2>
          <label><a href="https://playdogeared.com">playdogeared.com</a></label>
          <div className="status">
            <div className="status-item">
              <div className="total-played">
                <h3>{numberWithCommas(totalPlayed)}</h3>
                <span>Total Played</span>
              </div>
            </div>
            <div className="status-item w-50">
              <div className="total-matches">
                <h3>{numberWithCommas(totalMatches)}</h3>
                <span>Total Matches</span>
              </div>
            </div>
            <div className="status-item w-50">
              <div className="total-flipped">
                <h3>{numberWithCommas(totalFlipped)}</h3>
                <span>Total Flipped</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="analytics-chart">
        <div className="chart-container">
          <h4>Final Score</h4>
          <div className="chart-wrapper">
            {[0,1,2,3,4,5,6].map((index) => (
              <div className="chart-cell" key={index}>
                <span className="chart-cell-wrap">
                  <span className="amount">{matches[index]}</span>
                  <span className="amount-line" style={{height: `${ 269 * (matches[index] / max) }px`}}></span>
                  <span className="amount-number">{index}</span>
                </span>
              </div>
            ))}
          </div>
          <label>Last Updated: {now.toLocaleString()}</label>
          <button className="btn-refresh" type="button" onClick={() => {fetchData()}}>Refresh</button>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
